/* Style the button that is used to open and close the collapsible content */
.collapsible {
  /*color: #444;*/
  cursor: pointer;
  padding: $spacing * 0.5 $spacing * 0.75;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  position: relative;
  background-color: #e8e8e8;
  margin-bottom: $spacing * 0.5;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/*.active,
.collapsible:hover {
  background-color: #ccc;
}*/

/* Style the collapsible content. Note: hidden by default */
.content {
  /*padding: $spacing * 0.5 0 0 0;*/
  /*margin: $spacing * 0.25 0;*/
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  p {
    font-size: rem-calc(16);
    /*margin-bottom: 0;*/
  }
}

.collapsible:after {
  background-image: url("/media/img/maestranza/desplega-items.png");
  background-repeat: no-repeat;
  /*content: "\02795"; /* Unicode character for "plus" sign (+) */
  content: "";
  width: 12px;
  height: 19px;
  /*float: right;*/
  position: absolute;
  right: $spacing * 0.5;
  top: 14px;
}

.active {
  background-color: $primary-color;
  color: #fff;
}
.active:after {
  /*content: "\2796"; /* Unicode character for "minus" sign (-) */
  width: 19px;
  height: 12px;
  background-image: url("/media/img/maestranza/ico-tancar.png");
  background-repeat: no-repeat;
  top: 18px;
}
