.main-header {
  //@media #{$medium-up} {
  padding: $spacing 0;
  //}
  .site-logo {
    padding-bottom: $spacing;
    width: rem-calc(150);
    height: rem-calc(63);
    @media #{$medium-up} {
      /*margin-bottom: $spacing * 1.75;*/
      width: auto;
      height: auto;
    }
  }
  h1 {
    margin-bottom: 0;
  }
  .site-tools {
    font-size: rem-calc(14);
    display: none;
    @media #{$medium-up} {
      display: inline-block;
      /*position: absolute;
      top: 0;
      right: $column-gutter * 0.5;*/
      li {
        display: inline-block;
        color: $dark-grey;
        position: relative;
        padding-left: 0;
        &:before {
          display: none;
        }
        a {
          color: $dark-grey;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &.active {
            background: none;
          }
        }
        &::after {
          content: "|";
          margin: 0 $spacing * 0.5;
        }
        &.active {
          background: none;
        }
      }
      &.telf {
        padding-left: $spacing;
        position: relative;
        font-weight: bold;
        &::before {
          content: "";
          @include sprite("phone");
          top: 3px;
          left: -$spacing * 0.1;
          position: absolute;
        }
        li {
          &.last {
            &::after {
              display: none;
            }
          }
        }
        &.espai {
            margin-right: $spacing * 0.5;
            &::after {
                content: "|";
                margin-left: $spacing * 0.5;
            }
        }
      }
      &.email {
          padding-left: $spacing * 1.15;
          position: relative;
          &::before {
            content: "";
            @include sprite("ico-email");
            top: 3px;
            position: absolute;
            left: -$spacing * 0.2;
          }
      }
    }
    &-for-small {
      display: block;
      margin-bottom: 0;
      li {
        padding-left: 0;
        &:before {
          display: none;
        }
        a {
          text-decoration: none;
          display: block;
          padding: $spacing * 0.5;
          width: 100%;
          &:hover {
            background: #00894d;
          }
          &[aria-current="page"] {
            background: #00894d;
          }
        }
        &.last {
          /*padding: $spacing * 0.5;*/
        }
      }
      @media #{$medium-up} {
        display: none;
      }
      .site-tools {
        margin-bottom: 0;
        display: block;
      }
    }
  }
}
