.bg{
    &.grey{
        background-color: #4e5252;
        padding-top:2.2rem;
        padding-bottom:3rem;
        //margin-top:-1.2rem;
       // margin-bottom:-3rem;

        h1,h2,h3,h4,h5{
            color: #fff;
        }
        p{
            color: #fff;
        }
        .box{
            p{
                color:#4b4b4a;
            }
            a{
                border-color: #00a75e;
            }
        }
    }
}
