/* Slider */

.slider-container {
  padding: 0;
  overflow-y: hidden;
  @media #{$medium-up} {
    padding-right: $column-gutter * 0.5;
  }
}
.slider {
  /*margin-bottom: 0 !important;*/
  margin-bottom: $spacing * 0.5;
  height: $slider-h-sm;
  overflow-y: hidden;
  background-color: #eee;
  padding-bottom: $spacing * 2;

  @media #{$medium-up} {
    height: $slider-h-md;
  }
  @media #{$height} {
    height: $slider-h-lg;
  }
  .slick-slide > div {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    > div {
      display: flex;
    }
  }
  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    height: $slider-h-sm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    &--overlay {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &__caption {
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 $column-gutter/2;
    @media screen and (min-width: 40em) {
      width: 50%;
      // margin: auto;
    }
    @media #{$medium-up} {
      width: 70%;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    h2 {
      font-size: rem-calc(25);
      margin-bottom: $spacing * 0.5;
      line-height: 1;
      font-weight: 700;
      @media #{$medium-up} {
        font-size: rem-calc(40);
        margin-bottom: $spacing;
      }
      @media #{$large-up} {
        font-size: rem-calc(50);
      }
      @media #{$xxlarge-up} {
        font-size: rem-calc(65);
      }
    }
    p {
      line-height: 1.2;
      font-weight: normal;
      a {
        /*color: $dark-grey;*/
        color: white;
      }
    }
    h2,
    p {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      a {
        text-decoration: none !important;
        //color: #fff !important;
        background: transparent !important;
        -webkit-text-fill-color: inherit !important;
        text-underline-offset: 5px !important;
        text-decoration-color: #fff !important;
        text-decoration-thickness: 2px !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    h2 {
      font-weight: 900;
    }

  }
  .slick-dots {
    background: white;
    @include undolist;
    bottom: -10px;
    text-align: center;
    padding: $spacing * 0.5 $spacing;

    li {
      margin: 0 $spacing * 0.5 0 0;
      position: relative;
      top: -5px;
    }
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.box-slider {
  position: relative;
  margin-bottom: $spacing;
  .slider__caption,
  .slider-content {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background: rgba(66, 66, 66, 0.7);
    /*text-align: right;*/
    text-align: left;
    padding: $spacing;
    margin-right: 0;
    color: white;
    @media #{$medium-up} {
      top: auto;
      width: 400px;
      bottom: $spacing * 3.25;
      padding: $spacing * 1.75 $spacing * 1.5;
    }
    &.l {
      margin-left: 0;
    }
    h2,
    h1 {
      color: white;
      font-size: rem-calc(18);
      font-family: $title-font-family;
      margin-bottom: 0;
      a {
        color: white;
        display: block;
        text-decoration: none;
        font-weight: none;
        &:hover {
          text-decoration: underline;
        }
      }
      @media #{$medium-up} {
        font-size: rem-calc(28);
      }
    }
    p {
      font-size: rem-calc(16);
      margin-bottom: 0;
      color: white;
      .btn--blank {
        position: relative;
        text-decoration: none;
        padding-right: $spacing;
        &::before {
          content: "";
          position: absolute;
          right: -10px;
          top: -3px;
          @include sprite("fletxa-blanc");
        }
        &:hover {
          text-decoration: underline;
        }
      }
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
    }
    &.l {
      left: 0;
      text-align: left;
    }
    &.full {
      height: 100%;
      bottom: 0;
      text-align: left;
    }
  }
}

/* capçalera */
.bg {
  background: $primary-color;
  color: white;
}

@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("img/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"),
      slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 7px;
    width: 22px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      /*background: transparent;*/
      background: #e8e8e8;
      display: block;
      height: 7px;
      width: 22px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          /*opacity: $slick-opacity-on-hover;*/
          background: $dark-grey;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 22px;
        height: 7px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        /*opacity: $slick-opacity-not-active;*/
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      background: $dark-grey;
      /*opacity: $slick-opacity-default;*/
    }
  }
}
