.section {
  &--icons {
    li {
      h3 {
        font-size: rem-calc(16);
        text-transform: none;
      }

      &.centres-medics,
      &.cartera,
      &.especialitats,
      &.pacients {
        display: block;
        /*padding: $spacing * 2 $spacing * 2.5;*/
        padding-left: 0;
        padding-top: $spacing * 8;
        position: relative;
        h3 {
          margin-top: $spacing * 0.5;
          font-size: rem-calc(20);
        }
        p {
          color: $primary-color;
        }
      }
    }
    .row {
      margin-bottom: $spacing * 2;
      border-bottom: 3px $primary-color solid;
    }
  }
  &--icons2 {
    //portada
    ul {
        margin-bottom: 0;
    }
    li {
      padding: 0 0 $spacing * 0.3 0;
      @media #{$medium-up} {
        padding: .5rem;
      }
      img {
        margin-bottom: $spacing;
        /*-webkit-transition-duration: 0.2s;*/
          transition-duration: 0.2s;
        /*&:hover {
          -webkit-transform:scale(1.05);
          -moz-transform:scale(1.05);
          -ms-transform:scale(1.05);
          -o-transform:scale(1.05);
          transform:scale(1.05);
        }*/
      }
      h3 {
        text-transform: none;

      }
      a:hover img {
        -webkit-transform:scale(1.1);
        -ms-transform:scale(1.1);
        transform:scale(1.1);
      }
      a {
        text-decoration: none;
      }
     /* a {
        text-decoration: none;
        text-transform: none;
        display: block;
        color: $dark-grey;
        background-position: top center;
        background-repeat: no-repeat;

        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        &:hover {
          -webkit-transform:scale(1.05);
          -moz-transform:scale(1.05);
          -ms-transform:scale(1.05);
          -o-transform:scale(1.05);
          transform:scale(1.05);
        }
      }*/
      .marge-sup {
        padding-top: $spacing * 4;
        &.gran {
          padding-top: $spacing * 9;
        }
      }
      &:before {
        display: none;
      }
    }
    .row {
      margin-bottom: $spacing * 2;
      border-bottom: 3px $primary-color solid;
    }
  }
  &--news {
    padding-bottom: $spacing * 4;
    h3 {
      text-transform: none;
      font-size: rem-calc(18);
      margin-bottom: $spacing * 0.75;
      a {
        color: #000;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      font-size: rem-calc(15);
      color: #4b4b4a;
    }
    &.n2 {
      .columns {
        &:last-child {
          float: none;
          text-align: center;
          @media #{$medium-up} {
            float: right;
            text-align: right;
          }
        }
      }
    }
  }
  &--services {
    /*margin-bottom: $spacing * 4;*/
    .b {
      margin-bottom: $spacing * 1.5;
    }
  }
  &--speciality {
    margin-bottom: $spacing * 2;
    ul {
      background: #e8e8e8;
      padding: $spacing;
      font-size: rem-calc(16);
      li {
        break-inside: avoid;
        margin: $spacing * 0.5 0;
        &.first {
          margin-top: 0;
        }
        a {
          &:hover {
            color: $primary-color;
            text-decoration: none;
          }
        }
      }
      &.links {
        margin-bottom: 0;
        padding-left: 0;
        li {
          a {
            position: relative;
            padding-right: $spacing * 0.75;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              bottom: 0;
              @include sprite("ico-mes");
            }
          }
          &:before {
            display: none;
            padding-left: 0;
          }
          ul {
              padding: $spacing * 0.5 0 0 0;
              margin: 0;
          }
        }
      }
    }
    a {
      color: #000;
      text-decoration: none;
      font-size: rem-calc(16);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &--img-text {
    .row {
      margin-bottom: $spacing;
    }
  }
  &--faqs {
    margin-top: $spacing * 2;
    margin-bottom: $spacing;
  }
}

.quienes-somos {
  .section {
    &--icons2 {
      .row {
        border: 0;
      }
      ul {
        margin-bottom: 0;
        li {
          margin-bottom: 0;
          padding-bottom: $spacing * 0.25;
          h3 {
            background-size: 15%;
            @media #{$medium-up} {
              background-size: auto;
            }

          }
        }
        .marge-sup {
          padding-top: $spacing * 2;
          &.gran {
            padding-top: $spacing * 7;
            @media #{$medium-up} {
              padding-top: $spacing * 9;
            }
          }
          @media #{$medium-up} {
            padding-top: $spacing * 4;
          }
        }
      }
    }
  }
  h2 {
    color: $dark-grey;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

.home {
  .box {
    padding-top: 0;
  }
  .section--news {
    padding-bottom: 0;
    margin-bottom: $spacing * 2;
  }
}
.page-especialitats .box p,
.page-especialitats .box ul {
  margin-bottom: 0;
}
.page-especialitats h2 {
  color: $dark-grey;
}
/*.page-especialitats .box,
.news .box { */
.box {
  padding: $spacing;
  /*padding-top: 0;*/
  /*margin-bottom: $spacing;*/
  a {
    text-decoration: none;
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }
  &-blank {
    background-color: #e8e8e8;
    p {
      margin-bottom: $spacing * 0.5;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.marg-sup {
    margin-top: $spacing;
  }
  &-button {
    padding: 0;
    a {
      background-color: $primary-color;
      padding: $spacing * 0.75 $spacing;
      color: white;
      display: block;
      position: relative;
      border: 2px white solid;
      &::before {
        content: "";
        position: absolute;
        right: 10px;
        top: 15px;
        @include sprite("fletxa-blanc");
      }
      &:hover {
        color: $primary-color;
        background-color: white;
        text-decoration: none;
        border: 2px $primary-color solid;
        &::before {
          content: "";
          position: absolute;
          right: 19px;
          top: 22px;
          @include sprite("ico-mes");
        }
      }

    }
    &--back {
      a {
        padding-left: $spacing * 2;
        &::before {
          left: 10px;
          transform: rotate(180deg);
          -ms-transform: rotate(180deg); /* IE 9 */
          -webkit-transform: rotate(180deg); /* Opera, Chrome, and Safari */
        }
        &:hover {
          &::before {
            left: 18px;
          }
        }
      }
    }
    &.trans a{
      background: rgba(66,66,66,.7);
      &:hover {
        background: none;
      }
    }
  }
  &-docs {
    border: 2px $primary-color solid;
    a {
      color: $dark-grey;
    }
    p {
      margin-bottom: $spacing * 0.5;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.marg {
      margin-top: $spacing;
    }
  }

  h3 {
    text-transform: none;
    color: $primary-color;
    font-family: $title-font-family;
    font-size: rem-calc(18);
    margin-bottom: $spacing * 0.5;
    margin-top: $spacing * 0.25;
  }
  &-submenu {
    background: $primary-color;
    ul,
    h2 {
      color: white;
    }
    a {
      color: white;
      &:hover {
        text-decoration: underline;
        color: white;
      }
    }
  }
}

/* notícies */
.img-new {
  margin-bottom: $spacing;
}
.details {
  font-size: rem-calc(13);
  margin-bottom: $spacing * 0.5;
  &.details-fitxa {
    margin-bottom: $spacing;
  }
}
.news-list {
  img {
    margin-bottom: $spacing;
  }
  h2 {
    margin-bottom: $spacing * 0.5;
    a {
      text-decoration: none;
      color: $dark-grey;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  li {
    &:before {
      display: none;
    }
  }
}

/* contacto */
.contacto {
  .capsalera-sup {
    margin-bottom: $spacing;
    p {
      font-size: rem-calc(18);
      width: 100%;
      @media #{$medium-up} {
        width: 75%;
      }
    }
  }
  h2 {
    color: $primary-color;
    margin-top: $spacing;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  h3 {
    text-transform: none;
    font-size: rem-calc(18);
  }
  .main-content {
    address {
      margin-top: $spacing;
    }
  }

  .form-body {
    p {
      margin-bottom: 0;
    }
  }
  .form-enviat {
    @media #{$medium-up} {
      .btn {
        float: left;
        margin-right: $spacing;
      }
    }
  }
}
/* encuestas */
.encuesta {
  h2 {
    color: $primary-color;
  }
}

/* especialitats */
.img-top {
  background-repeat: no-repeat;
  background-size: cover;

  margin-bottom: $spacing * 2;

  .pos-container {
    position: relative;
    min-height: 15rem;
    @media #{$medium-up} {
      min-height: 25rem;
    }
    .text-box {
      margin-top: $spacing * 2;
      margin-bottom: $spacing * 2;
      background: rgba(255, 255, 255, 0.65);
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      @media #{$medium-up} {
        width: 45%;
      }
      p {
        font-size: rem-calc(16);
        margin-bottom: 0;
        padding: $spacing;
        @media #{$medium-up} {
          font-size: rem-calc(20);
        }
      }
    }
  }
}
.especialistes-list {
  /*margin: 0;*/
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  li {
    &:before {
      display: none;
    }
    .contingut {
      background: #e8e8e8;
      padding: $spacing * 2 $spacing * 2.25 $spacing $spacing;
      position: relative;
      height: 100%;
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 10px;
        @include sprite("fletxa-verda");
        right: $spacing * 1.5;
        bottom: $spacing;
      }
      h2 {
        font-family: $title-font-family-light;
        @media #{$medium-up} {
          font-size: rem-calc(30);
        }
        color: black;
        margin-bottom: $spacing * 0.5;
        span {
          font-family: $title-font-family;
        }
        a {
          color: black;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        margin: 0;
        &.nom-esp {
          color: $primary-color;
          font-weight: 700;
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &.nom-car {
          margin-bottom: $spacing * 0.25;
        }
      }
    }
  }
}
/* formulari cerca especialistes */
.form-cercar {
  border-bottom: 2px #e8e8e8 solid;
  display: block;
  margin-bottom: $spacing * 2;
  padding-bottom: $spacing * 1.5;
  .cercar-paraula {
    input {
      width: 100%;
      float: none;
      height: 3.1rem;
      @media #{$medium-up} {
        width: 45%;
        float: left;
      }
    }
  }
  .cercar-enviar {
    input {
      margin-left: 0;
      float: none;
      padding: .85rem 1.125rem;
      @media #{$medium-up} {
        float: left;
        margin-left: $spacing;
      }
    }
  }
}

.header-especialistes {
    background: #e8e8e8;
    margin-bottom: $spacing * 2;
    min-height: 315px;
    position:relative;
    display: table;
    width: 100%;
    .text {
        position: absolute;
        bottom: $spacing * 2;
        left: $spacing * 3;
    }
    .frase {
      background: $dark-grey;
      color: white;
      font-size: rem-calc(20);
      font-style: italic;
      float: right;
      padding-top: $spacing * 4;
      min-height: auto;
      width: 100%;
      @media #{$medium-up} {
        min-height: 315px;
        width: 32%;
      }
      &:before {
        content: "";
        position: absolute;
        top: $spacing;
        margin: 0 auto;
        @include sprite("ico-cometes");
      }
    }
    img {
      float: left;
      margin-right: $spacing * 2;
    }
    span {
      text-transform: uppercase;
    }

    h1 {
      font-family: $title-font-family-light;
      line-height: 1.2;
      @media #{$medium-up} {
        font-size: rem-calc(45);
      }
      color: black;
      span {
        font-family: $title-font-family;
        text-transform: none;
      }
    }
    p {
      margin-bottom: $spacing * 0.5;
    }
  }

/*.header-especialistes {
  background: #e8e8e8;
  margin-bottom: $spacing * 2;
  min-height: 315px;
  .container {
      position: relative;
  }
  .row {
    position: relative;
    .columns {
      position: static;
      .text {
        position: static;
        left: $spacing * 3;
        top: $spacing;
        padding: $spacing;
        padding-right: 0;
        @media #{$medium-up} {
          position: absolute;
          padding-right: 300px;
        }
        @media #{$large-up} {
          padding-right: 500px;
        }
      }
    }
  }

  .frase {
    background: $dark-grey;
    color: white;
    font-size: rem-calc(20);
    font-style: italic;
    position: relative;
    padding-top: $spacing * 4;
    min-height: auto;
    @media #{$medium-up} {
      min-height: 315px;
    }
    &:before {
      content: "";
      position: absolute;
      top: $spacing;
      margin: 0 auto;
      @include sprite("ico-cometes");
    }
  }
  img {
    float: left;
    margin-right: $spacing * 2;
  }
  span {
    text-transform: uppercase;
  }

  h1 {
    font-family: $title-font-family-light;
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(45);
    }
    color: black;
    span {
      font-family: $title-font-family;
      text-transform: none;
    }
  }
  p {
    margin-bottom: $spacing * 0.5;
  }
}*/
//companyies asseguradores
.companies {
  li {
    &:before {
      display: none;
    }
  }
}
