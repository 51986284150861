.btn--blank a,
.btn a {
  /*margin-top: $spacing;*/
  border: 2px $primary-color solid;
  text-transform: none;
  color: $primary-color;
  font-size: rem-calc(15);
  border-radius: 0;
  position: relative;
  text-align: center;
  padding: $spacing * 0.25 $spacing * 2 $spacing * 0.25 $spacing * 0.5;
  display: inline-block;
  font-weight: bold;
  font-size: rem-calc(15);
  text-decoration: none;
  transition: 0.15s;
  transition-property: color, background-color;
  background: transparent;
  &::after {
    content: "";
    position: absolute;
    display: block !important;
    font-size: 1rem;
    @include sprite("ico-mes");
    right: $spacing * 0.25;
    top: 11px;
  }
  &:hover {
    color: #fff;
    background-color: $primary-color;
    text-decoration: none;
    &::after {
      @include sprite("ico-mes-blanc");
    }
  }
  a {
    text-decoration: none;
  }
}

.btn a {
  background: $primary-color;
  color: white;
  &::after {
    @include sprite("ico-mes-blanc");
  }
  &:hover {
    background: transparent;
    border: 2px $primary-color solid;
    color: $primary-color;
    &::after {
      @include sprite("ico-mes");
    }
  }
}

.btn-gris {
  a {
    display: block;
    background: #e8e8e8;
    padding: $spacing * 0.5 $spacing * 0.75;
    width: 100%;
    text-decoration: none;
    color: #000;
    padding-right: $spacing * 5;
    position: relative;
    margin-bottom: $spacing * 0.5;
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      right: $spacing * 0.5;
      @include sprite("ico-extern-copia");
    }
    &:hover {
      background: #c6c6c6;
    }
  }
  margin-bottom: 0;
}
