.logos-list {
    font-size: 0;
    li {
        padding: 1.5rem .5rem;
        margin-bottom: 0;
        vertical-align: middle;
        display: inline-block;
        float: none;
        font-size: 1rem;
    }
    img {
        margin-bottom: 0;
        max-height: 70px;
    }
}