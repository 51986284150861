.main-footer {
  padding: 0;
  color: $dark-grey;
  background: #f1f1f1;
  margin-top: $spacing * 3;
  .contact {
    background: $dark-grey;
    padding: $spacing 0;
    /*margin-bottom: $spacing * 1.5;*/
    font-size: rem-calc(15);
    text-align: center;
    /*@media #{$medium-up} {
      margin-bottom: $spacing * 2;
    }*/
    span {
      font-size: rem-calc(20);
    }
    p {
      color: #fff;
      margin-bottom: 0;
      display: inline-block;
      text-align: left;
      position: relative;
      font-size: rem-calc(15);
      &::before {
        content: "";
        //@include sprite("ico-phone-footer");
        @include sprite("ico-email-peu");
        left: -$spacing * 3;
        top: 5px;
        position: absolute;
      }
    }
    a {
        color: white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
  }
  .ample-fixe {
    text-align: center;
    margin-bottom:$spacing * 1.5;
    @media #{$medium-up} {
      width: 240px;
      text-align: left;
      margin-bottom:0;
    }
    &:last-child {
      margin-bottom:0;
    }
  }
  .subfooter {
      padding: $spacing * 1.5 0 $spacing 0;
      @media #{$medium-up} {
        padding: $spacing * 2 0 $spacing * 2 0;
      }
  }
  .copyright {
    color: $dark-grey;
    padding: $spacing 0;
    /*margin-top: $spacing;*/
    background: #f9f9f9;
    /*@media #{$medium-up} {
      margin-top: $spacing * 2;
    }*/
  }
  p {
    font-size: rem-calc(14);
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
    li {
      display: inline;
      font-size: rem-calc(13);
      padding-left: 0;
      &::after {
        content: "|";
        margin-left: $spacing * 0.5;
        margin-right: $spacing * 0.5;
        color: $dark-grey;
      }
      &:last-child::after {
        display: none;
      }
      &:before {
        display: none;
      }
      a {
        text-decoration: none;
        color: $dark-grey;
        &:hover {
          text-decoration: underline;
        }
      }
      &.active {
        background: none;
        a {
          background: none;
          text-decoration: underline;
        }
      }
    }
  }
  .share {
    margin-top: $spacing * 0.5;
    li {
      a {
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  address {
    /*font-style: normal;*/
    font-size: rem-calc(14);
    a {
      color: $dark-grey;
    }
  }
  img {
    margin-bottom: $spacing * 0.5;
  }
}

/*address,
.main-footer p:first-child {
  font-style: normal;
  font-size: 1rem;
  &::before {
    content: "";
    @include sprite("logo-peu");
    display: block;
    margin: 0 auto $spacing * 0.5;
  }
}*/
