nav {
  background-color: $primary-color;
  @media #{$medium-up} {
    margin-top: 0 !important;
  }
}
.main-nav {
  font-size: rem-calc(15);
  margin-bottom: 0;
  @include undolist;
  li {
    display: block;
    margin-bottom: 0;
    font-weight: bold;
    @media #{$medium-up} {
      /*display: inline-block;*/
      float: left;
    }
    /*&.last {
      @media #{$medium-up} {
        float: right;
      }
      a {
        color: #fff;
        background: #4b4b4a;
        text-transform: uppercase;
        &:hover {
          background: #00894d;
        }
        &[aria-current="page"] {
          background: #00894d;
        }
      }
    }*/
  }
  a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: $spacing * 0.5;
    @media (max-width: 960px) and (min-width: 800px){
      padding:  $spacing * 0.5 $spacing * 0.25;
      font-size: rem-calc(14);
    }
    @media (min-width: 960px) {
      padding: $spacing * 0.75;
    }

    &:hover {
      background: #00894d;
    }
    &[aria-current="page"] {
      background: #00894d;
    }
  }
}

//NAVIGATION

// HAMBURGER
// mòbil
.hamburger {
  cursor: default;
  /*margin-bottom: $spacing;*/
  /*background: #3d3d3b; */
  background-color: transparent;
  width: 60px;
  height: 60px;
  /* @media screen and (min-width: 40em) { */
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  /* } */
}
.hamburger.is-active {
  /*background-color: #3d3d3b;*/
  background: transparent;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::after,
.hamburger.is-active .hamburger-inner::before {
  background-color: #000;
}
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #000;
}

//

#menu {
  @media screen and (max-width: 49.9375em) {
    //800px
    margin-left: -$column-gutter * 0.5;
    margin-right: -$column-gutter * 0.5;
    padding: $spacing $column-gutter * 0.5 $spacing * 1.5;
    /*background: #3d3d3b;*/
    /*margin-top: -8px;*/
    margin-bottom: $spacing;
    a {
      color: white;
    }
    li {
      margin: 0;
    }
  }
}

.navigation {
  text-align: center;
  /*margin-top: $spacing;*/
  /*margin: $spacing 0;*/
  @media #{$medium-up} {
    /*margin-bottom: $spacing * 2;*/
    text-align: left;
  }
  button {
    @media #{$medium-up} {
      display: none;
    }
  }
}

@media #{$medium-up} {
  #menu {
    display: block;
  }
}

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  /*&:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }*/

  &.is-active {
    /*&:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }*/

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

/*
   * Squeeze
   */
.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
