.pagination-centered {
  text-align: center;
  margin-top: 0;
  @include grid-row();
  padding: vr(1) vr(0.5);
}
ul.pagination {
  margin-left: 0;
  list-style: none;
  @include undolist;
  li {
    margin-bottom: 0;
    display: none;

    &.arrow {
      display: block;
      &.unavailable {
        display: none;
      }
    }
    @media #{$medium-up} {
      display: inline-block;
      &.arrow {
        display: none;
      }
    }
    &.arrow a {
      background-color: transparent;
      @extend .btn;
      height: auto;
      width: auto;
      font-size: rem-calc(14) !important;
      padding: vr(0.2) vr(0.5) !important;
      text-transform: none !important;
      &:hover {
        opacity: 0.8;
      }
      /*&:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
      }*/
    }
    &.prev {
      float: left;
      a {
        &:before {
          // @include sprite('pag-back');
        }
      }
    }
    &.next {
      float: right;
      a {
        &:before {
          // @include sprite('pag-next');
        }
      }
    }
    &.unavailable a {
      cursor: default;
      color: #979797;
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
      }
    }
    &.current a {
      background-color: $primary-color;
      color: #fff;
    }
    &.hidden {
      @media #{$small-only} {
        display: none;
      }
    }
  }
  a {
    border-bottom: none;
    background-color: #ebebeb;
    /*width: $spacing * 0.25;
    height: $spacing * 0.25;*/
    padding: $spacing * 0.5 $spacing * 0.75;
    display: block;
    margin: $spacing * 0.25;
    text-decoration: none;
    color: $dark-grey;
    &:hover {
      background-color: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
}
