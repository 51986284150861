
/*.houdiniForm*/
form {
  &:not(.filters-form) {
    /*@include stack;*/
    fieldset {
      @include stack;
    }
  }

  fieldset {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    > div {
      &:last-child {
        margin-bottom: 0;
      }
      .columns {
        @media screen and (max-width: 49.9375em) {
          margin-top: $spacing;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  legend {
    font-weight: bold;
  }
  .label,
  label,
  p.type-label {
    font-size: rem-calc(16);
    display: block;
    margin-bottom: $spacing;
    //text-transform: uppercase;
    color: $dark-grey;
    &.simple-label {
      text-transform: none;
      letter-spacing: 0;
      font-weight: 300;
      /*font-size: rem-calc(16);*/
    }
    &.error {
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      color: #d60000;
      margin-top: $spacing * 0.25;
      /*font-size: rem-calc(14);*/
    }
    .form-wrapper & {
      color: #fff;
    }
    .info {
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0;
    }
  }
  .description {
    font-size: rem-calc(14);
  }
  .simple-label label {
    @extend .simple-label;
  }
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  textarea,
  select {
    border: 0;
    /*border-radius: 38px;*/
    color: #313131;
    font-weight: normal;
    background-color: #e8e8e8;
    padding: $spacing * 0.25 $spacing * 0.75;
    width: 100%;
    height: rem-calc(52);
    box-shadow: none;
    transition: background-color 0.1s;
    margin-bottom: $spacing * 0.5;
    &.error {
      /*border-color: #f00;*/
      border: 1px #f00 solid;
    }
    /*&:focus {
      background-color: #fefefe;
    }*/
  }
  textarea {
    height: $spacing * 10;
  }
  select {
    color: #9da1a6;
    appearance: none;
    /*border: 1px $body-font-color solid;*/
    background-color: #e8e8e8;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE5IDExIj4KICAgIDxwYXRoIGQ9Ik0xNi43NjcgMEw5LjUgNi43OTkgMi4yMzIgMCAwIDIuMDkzIDkuNSAxMSAxOSAyLjA5M3oiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
  }
  li {
    border: 0;
  }

  .form-top {
    .columns {
      margin-bottom: $spacing;
    }
  }

  .data {
    //data-naixement
    div {
      width: 15%;
      position: relative;
      display: inline-block;
      margin-right: $spacing * 1;
      &:after {
        content: "/";
        position: absolute;
        right: -1.25rem;
        top: 1rem;
      }
      &.aaaa {
        width: 20%;
        &:after {
          display: none;
        }
      }
      label {
        text-align: center;
      }
    }
  }

  .inputs-list {
    &--radios {
      label,
      li {
        display: inline-block;
        text-transform: none;
        margin-right: $spacing * 0.5;
        font-size: rem-calc(15);
        @media #{$medium-up} {
          margin-right: $spacing * 1.25;
        }
        span {
          padding-top: 3px;
          display: inline-block;
        }
      }
    }
  }
  .protecciodades {
    margin-top: 0;
    margin-bottom: $spacing;
    label {
      text-transform: none;
      font-size: rem-calc(15);
    }
  }
  .help-form {
    font-style: italic;
    margin-top: -$spacing * 0.5;
  }
  p.type-label {
    margin-bottom:0;
    margin-top: $spacing * 0.5;
  }
  .text-form {
    margin-bottom: $spacing;
  }

  // checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
  // CAL REVISAR I SIMPLIFICAR CODI
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      --active: #606f72;
      --active-inner: #fff;
      --focus: 0;
      --border: 0;
      --border-hover: 0;
      --background: #d8d8d8;
      --disabled: grey;
      --disabled-inner: grey;
    }
    input[type="radio"] {
      --active: #fff;
      --active-inner: #00a75e;
      --focus: 1px #00a75e;
      //--border: 1px grey;
      //--border-hover: 1px #00a75e;
      --background: #fff;
      --disabled: grey;
      --disabled-inner: grey;
    }

    input[type="checkbox"],
    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;

      height: $spacing;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      // cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.1s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      // &:disabled {
      //   --b: var(--disabled);
      //   cursor: not-allowed;
      //   opacity: 0.9;
      //   &:checked {
      //     --b: var(--disabled-inner);
      //     --bc: var(--border);
      //   }
      //   & + label {
      //     cursor: not-allowed;
      //   }
      // }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      width: $spacing;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 0.75;
      }
    }
    input[type="checkbox"] {
      border-radius: 2px;
      &:after {
        width: 7px;
        height: 11px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 8px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    input[type="radio"] {
      border-radius: 50%;
      &:after {
        /*width: 16px;
        height: 16px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 1;
        transform: scale(var(--s, 0.7));
        top: 4px;
        left: 4px;*/
      }
      &:checked {
        --s: 1;
        border: 1px #00a75e solid;
        &:after {
          transform: scale(var(--s, 0.7));
          left: 4px;
          top: 4px;
        }
      }
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    margin-right: $spacing * 0.25;
  }

  .esq {
    margin-bottom: $spacing;
    @media #{$medium-up} {
      margin-top: $spacing;
      label {
        width: 15%;
        float: left;
        margin-top: 15px;
      }
      input {
        width: 83%;
        float: right;
      }
    }
  }
}


.form-encuesta {
  margin-bottom: $spacing * 2;
  width: 100%;
  @media #{$medium-up} {
    width: 60%;
  }
  label {
    margin-top: $spacing;
    margin-bottom: $spacing * 0.5;
  }
  .box-steps {
    @media #{$medium-up} {
      position: absolute;
      right: 0;
      bottom: $spacing * 2;
    }
    p {
      color: $primary-color;
      margin: 0;
      @media #{$medium-up} {
        text-align: right;
      }
    }
  }
  h2 {
    margin-top: $spacing * 1.5;
  }
}




// checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
// PENDENT REVISAR I SIMPLIFICAR CODI
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #4a4a4a;
    --active-inner: #249ea8;
    --focus: 3px lightskyblue;
    --border: #bfbfbf;
    --border-hover: #999;
    --background: linear-gradient(to top, #d4d4d4 0%, #f2f2f2 100%);
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;

    -webkit-appearance: none;
    -moz-appearance: none;

    height: $spacing * 1.25;
    width: $spacing * 1.25;
    outline: none;
    display: inline-block;
    // vertical-align: top;
    position: relative;
    margin: 0;
    // cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.1s);
    }
    &:checked {
      // --b: var(--active);
      // --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    // &:disabled {
    //   --b: var(--disabled);
    //   cursor: not-allowed;
    //   opacity: 0.9;
    //   &:checked {
    //     --b: var(--disabled-inner);
    //     --bc: var(--border);
    //   }
    //   & + label {
    //     cursor: not-allowed;
    //   }
    // }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    // &:focus {
    //   box-shadow: 0 0 0 var(--focus);
    // }

    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  input[type="checkbox"] {
    border-radius: 2px;
    &:after {
      width: 8px;
      height: 13px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 10px;
      top: 5px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }
  input[type="radio"] {
    border-radius: 50%;
    &:after {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      // transform: scale(var(--s, 0.7));
      top: 6px;
      left: 7px;
    }
    &:checked {
      --s: 0.5;
      &:after {
        background: var(--active-inner);
        opacity: 1;
      }
    }
  }
}
input[type="checkbox"],
input[type="radio"] {
  margin-right: $spacing * 0.25;
}

button[type="submit"],
input[type="submit"] {
  background: $primary-color;
  font-size: rem-calc(15);
  border: 0;
  color: white;
  text-transform: uppercase;
  padding: $spacing * 0.5 $spacing * 0.75;
  border: 2px white solid;
  &:hover {
    background: white;
    color: $primary-color;
    border: 2px $primary-color solid;
    transition: background-color 0.1s;
  }
}

.error-envio {
  border: 2px #d60000 solid;
  padding: $spacing;
  text-align: center;
  p {
    color: #d60000;
    margin: 0;
  }
}

div#gmap {
  height: 300px;
  text-align: center;
  font-size: 0.75em;
  line-height: 120%;
  min-height: 515px;
  margin-bottom: $spacing * 2;
}
div#gmap img {
  max-width: none;
}
