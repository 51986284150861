// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  /*font-weight: 300;*/
}
h1,
.t1 {
  color: $dark-grey;
  font-size: rem-calc(28);
  font-family: $title-font-family;
  margin-bottom: $spacing;
  &.sm {
    color: $dark-grey;
    font-size: rem-calc(28);
    font-family: $title-font-family;
    margin-bottom: $spacing;
  }
}
h2,
.t2 {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: $spacing * 0.75;
  font-family: $title-font-family;
  color: $primary-color;
  &.b {
    color: $primary-color;
    font-size: rem-calc(22);
    text-align: left;
    font-family: $title-font-family;
    margin-bottom: $spacing;
    @media #{$medium-up} {
      margin-bottom: 0;
    }
  }
  &.g {
    color: $dark-grey;
  }
}
h3,
.t3 {
  font-size: rem-calc(16);
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: $spacing * 0.5;
  color: $dark-grey;
  font-weight: normal;
}
h4,
.t4 {
  font-size: rem-calc(14);
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: $spacing;
  color: $primary-color;
}
h5,
.t5 {
  font-size: rem-calc(14);
  line-height: 1.2;
  margin-bottom: $spacing;
  color: $dark-grey;
}
p,
ol,
ul,
dl {
  font-size: rem-calc(16);
  margin-bottom: $spacing;
  // line-height: 1.6;
  // @media #{$medium-up} {
  //   font-size: rem-calc(18);
  // }
  color: $dark-grey;
  @media #{$large-up} {
    font-size: rem-calc(17);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(17);
  }
  ul {
    margin-bottom: 0;
  }
  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}


dl,
dt,
dd,
figure,
img,
form,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}
hr {
  margin: $spacing * 2 0;
  border: 0;
  border-top: 1px solid #d9d9d9;
}
// .main-content {
//   ul,
//   ol,
//   figure {
//     @include stack-small;
//   }
// }
ul {
  list-style: none;
  li {
    position: relative;
    padding-left: $spacing;
    margin-bottom: $spacing * 0.25;
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      @include sprite("ico-llista");
    }
    ul {
      margin-top: $spacing * 0.25;
    }
  }
  &.llista-dest {
    li {
      display: inline-block;
      background: $dark-grey;
      border-radius: 10px;
      color: white;
      padding: $spacing * 0.5 $spacing;
      &:before {
        display: none;
      }
      p,
      a {
        color: white;
        margin: 0;
      }
    }
  }
  &.rel {
    a {
      position: relative;
      border: 1px #000 solid;
      font-weight: 700;
      width: auto;
      border-radius: 10px;
      color: #000;
      text-decoration: none;
      padding: $spacing * 0.5 $spacing;
      &:after {
        position: absolute;
        content: "";
      }
    }
    li {
      padding-left: 0;
      margin-bottom: $spacing * 1.5;
      &:before {
        display: none;
      }
      &.download-doc {
        a {
          padding-right: $spacing * 5;
          &:after {
            top: 10px;
            right: $spacing;
            @include sprite("ico-descarrega");
          }
        }
      }
      &.external {
        a {
          padding-right: $spacing * 5;
          &:after {
            top: 10px;
            right: $spacing;
            @include sprite("ico-extern");
          }
        }
      }
    }
  }
}

ol {
  margin-top: $spacing * 0.25;
  padding-left: $spacing;
  li {
    margin-bottom: $spacing * 0.25;
  }
}

figcaption {
  font-size: rem-calc(12);
}

::selection {
  // color: #fff;
  background-color: #d0d0d0;
  -webkit-text-fill-color: $body-font-color;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $link-color;
  &.categ {
    text-transform: uppercase;
  }
}

// focus
a,
button,
input,
select,
textarea,
[role="button"] {
  &:focus {
    outline: 2px solid $focus-color;
  }
}

.box-frame {
  padding: $spacing * 2;
  border: 3px $primary-color solid;
  margin: $spacing 0;
  p {
    font-size: rem-calc(16);
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    margin: 0;
  }
}

.text-entrada {
  color: $dark-grey;
  margin-top: $spacing;
  .dest {
    font-family: $title-font-family;
    font-size: rem-calc(24);
    margin-bottom: $spacing;
  }
  p {
    font-size: rem-calc(18);
  }
}

address {
  font-style: normal;
}

.dest {
  @include stack;
  line-height: 1.63;
  padding: $spacing;
  position: relative;
  text-align: center;
  font-weight: bold;
}
.dest::before,
.dest::after {
  content: "";
  background: $primary-color;
  display: block;
  height: 4px;
  width: 30%;
  margin: 0 auto $spacing;
}
.dest::after {
  margin: $spacing auto 0;
}
.llista-caixes,
.llista-caixes--bg {
  @media #{$medium-up} {
    display: flex;
    justify-content: space-between;
  }
  li {
    background: $primary-color;
    color: white;
    border-radius: 27px;
    flex-wrap: wrap;
    padding: $spacing * 2;
    width: 100%;
    margin-top: $spacing;
    @media #{$medium-up} {
      width: 48%;
      margin-top: 0;
    }
    &:before {
      display: none;
    }
    a,
    p,
    h2,
    h3,
    h4 {
      color: white;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.llista-caixes--bg {
  li {
    background: $dark-grey;
  }
}

table:not(.table-condensed){
  width: 100%;
  font-size: rem-calc(16);
  /*font-weight: normal;*/
  margin-bottom: $spacing;
  th,
  td {
    /*vertical-align: baseline;*/
    padding: $spacing * 0.65;
  }
  thead {
    th {
      color: #fff;
      background: #6f6f6e;
      text-align: left;
      line-height: 1.2;
      &:nth-child(2n) {
        background: $dark-grey;
      }
    }
  }
  tbody {
    td,
    th {
      text-align: left;
      background: #edf0f0;
    }
    & td:nth-child(2n) {
      background: #e4e7e8;
    }
    & tr {
      border-bottom: 2px solid #fff;
    }
    p {
      margin-top: $spacing * 0.5;
    }
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
